// @flow

import React from 'react'
import type { Node, Element } from 'react'

type Props = {
  children: Node,
}

function DefaultPage({ children }: Props): Element<'div'> {
  return (
    <div className="md:max-w-xl md:mx-auto">
      <div className="px-6">{children}</div>
    </div>
  )
}

export default DefaultPage
