// @flow

import type { Node } from 'react'
import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import DefaultPage from '../../components/default-page'
import { MarkdownContent } from '../../components'
import type { Post } from './types'
import { PostContext } from '../../context'

type Props = {
  data: {
    contentfulBlogPost: Post,
    site: {
      siteMetadata: {
        title: string,
      },
    },
  },
}

function BlogPostTemplate({ data }: Props): Node {
  const { contentfulBlogPost: post } = data
  const { title: siteTitle } = data.site.siteMetadata
  const pageTitle = `${post.title} | ${siteTitle}`

  return (
    <PostContext.Provider value={post}>
      <Layout>
        <Helmet title={pageTitle} />
        <DefaultPage>
          <div className="mb-8 md:mb-16 pt-8 md:pt-16">
            <div className="mb-2">
              {post.title ? (
                <h1 className="text-3xl md:text-5xl leading-tight font-bold">
                  {post.title}
                </h1>
              ) : (
                <h1>No Title</h1>
              )}
            </div>
            <h4 className="text-md text-grey-darker">{post.publishDate}</h4>
          </div>
          {post.body && (
            <MarkdownContent
              id="post-content"
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.html,
              }}
            />
          )}
        </DefaultPage>
      </Layout>
    </PostContext.Provider>
  )
}

export default BlogPostTemplate

export const pageQuery: any = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
